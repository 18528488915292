import { tpl } from './template';
import { qs } from '../helpers';

export function Popover(options) {
  'use strict';

  const defaults = {
    target: '',
    container: '',
    parentContainer: '',
    pwd: {}
  };

  if (typeof options === 'object') {
    if(options.container.nodeName !== 'DIV') {
      throw new Error('First argument (input) must be an div element');
    }
    options = Object.assign({}, defaults, options);
  } else {
    options = defaults;
  }

  if (!options.container) {
    throw new Error('Missing input argument');
  }

  if (typeof options.parentContainer === 'string') {
    options.parentContainer = qs(`#${options.parentContainer}`, options.container)
  }

  const template = () => {
    const div = document.createElement('div');
    div.innerHTML = tpl;

    const container = div.firstElementChild;

    return container;
  };

  const addClass = (classType, element) => {
    const classes = ['true', 'false'];

    element.classList.remove(...classes);

    if(classType) {
      element.classList.add(`${classType}`);
    }
  };

  const init = () => {
    const popoverOld = qs('.pwd-strength-popover', options.parentContainer);
    const popover = template();

    if(popoverOld) {
      options.parentContainer.replaceChild(popover, popoverOld);
    } else {
      options.parentContainer.appendChild(popover);
    }

    show(popover);
  };

  const update = (optionsPwd) => {

    if(!optionsPwd) {
      throw new Error('Options must be a object');
    }

    const sectionPopover = options.parentContainer;

    const upperAndlowerCase = qs('.upper-and-lower-case', sectionPopover);
    const securityLevel = qs('.security-level', sectionPopover);
    const lengthFlag = qs('.length-flag', sectionPopover);
    const digitFlag = qs('.digit-flag', sectionPopover);

    [upperAndlowerCase, securityLevel, lengthFlag, digitFlag].forEach(item => {
      addClass(null, item);
    });

    // Digit Flag
    if(!optionsPwd.str.digitFlag) {
      addClass('false', digitFlag);
    }

    if(optionsPwd.str.digitFlag) {
      addClass('true', digitFlag);
    }

    // Length Flag
    if(!optionsPwd.str.lengthFlag) {
      addClass('false', lengthFlag);
    }

    if(optionsPwd.str.lengthFlag) {
      addClass('true', lengthFlag);
    }

    // Uppercase and Lowercase
    if(!optionsPwd.str.upperCaseFlag || !optionsPwd.str.lowerCaseFlag) {
      addClass('false', upperAndlowerCase);
    }

    if(optionsPwd.str.upperCaseFlag && optionsPwd.str.lowerCaseFlag) {
      addClass('true', upperAndlowerCase);
    }

    // Security level
    if(!optionsPwd.pwd.highLevelSecurity) {
      addClass('false', securityLevel);
    }
    if(optionsPwd.pwd.highLevelSecurity) {
      addClass('true', securityLevel);
    }
  };

  const show = (popover) => {
    popover.style.top = positionY(options.target);
    popover.style.left = positionX(options.target);
    popover.style.display = `block`;
  };

  const positionY = (target) => {
    return `${(target.offsetTop) - 10}px`;
  };

  const positionX = (target) => {
    return `${target.offsetLeft + target.clientWidth + 30}px`;
  };

  return { init, update };
}

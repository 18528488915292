import { parseToQueryString } from './helpers';
import * as Util from './util';
import axios from 'axios';

export function FormNoAjax() {
  'use strict';

  const _storage  = window.localStorage;
  const qString = window.location.search;
  const form  = document.querySelector('#form-login');
  const btnSubmit = form.querySelector('#submit-button');
  const inputUser = form.querySelector('#user');
  const inputPwd = form.querySelector('#password');

  const formSSO = document.querySelector('#form-sso');
  const btnVerifySSO = formSSO.querySelector('#verify-sso-button');
  const inputUserSSO = formSSO.querySelector('#user-sso');

  const loading = document.getElementsByClassName('login-loading')[0];

  function bindEvents() {
    inputUser.addEventListener('keyup', checkBtnSubmit, false);
    inputPwd.addEventListener('keyup', checkBtnSubmit, false);

    inputUserSSO.addEventListener('keyup', checkBtnVerifySSO, false);

    inputUser.addEventListener('blur', function(event) {
      const target = event.currentTarget;
      _storage.setItem('loginUserNameTemp', target.value);
    });

    inputPwd.addEventListener('blur', function(event) {
      const target = event.currentTarget;
      _storage.setItem('loginUserPwdTemp', target.value);
    });

    form.addEventListener('submit', function() {
      _showLoading();
      setTimeout(function() {
        _hideLoading();
        _endingLoading();
      }, 4000);
      inputUser.disabled = false;
      verifySaveLogin();
      resetFormStyles();

    }, false);

    formSSO.addEventListener('submit', function(event) {
      event.preventDefault();
      _showLoading();
      cleanAlertTopLogin();
      submitSSO();
    }, false);

  }

  function submitSSO() {
    const emailSSO = inputUserSSO.value;
    
    axios.post('/api/auth/login/configure', { email: emailSSO }
    ).then(function (response) {
      
      if (response.data && response.data.sso) {
        window.location = response.data.ssoRedirect;
        return;
      }
  
      nextLogin();
    }).catch(function (err) {
      nextLogin();
    });
  }

  function nextLogin() {
    var verifyUser = null;
    var loginData = JSON.parse(window.localStorage.getItem('loginData'));
    if (loginData) verifyUser = loginData.email;

    if (inputUserSSO.value !== verifyUser) {
      inputUser.value = inputUserSSO.value;
      inputPwd.value = '';
      document.getElementById('rememberData').checked = false;
      _storage.removeItem('rememberData');
      userTouched();
      passTouched(inputPwd);
      checkBtnSubmit();
    } else {
      checkLocalStorage();
    }

    _hideLoading(); // trasition de 1s

    setTimeout(function() {
      document.getElementById('sso-section').classList.add('hide-section');
      document.getElementById('login-section').classList.remove('hide-section');
      _endingLoading();
    }, 1000);
  }

  function _showLoading() {
    loading.classList.remove('hide-section');
  }

  function _hideLoading() {
    loading.getElementsByClassName('top-loading')[0].style.opacity = 1;
  }

  function _endingLoading() {
    loading.classList.add('hide-section');
    loading.getElementsByClassName('top-loading')[0].style.opacity = 0;
  }

  function verifySaveLogin() {
    if (document.getElementById('rememberData').checked) {
      Util.storageUserdata(inputUser.value,inputPwd.value);
    }
    _storage.setItem('loginUserNameTemp', inputUser.value);
    _storage.setItem('loginUserPwdTemp', inputPwd.value);
  }

  function checkInputs() {
    var loginUserName = _getUserName();
    var loginUserPwd = _getUserPwd();

      if(loginUserName && loginUserPwd) {
        _enableBtnSubmit();

        form.querySelector('#user').value = loginUserName;
        form.querySelector('#password').value = loginUserPwd;
        if(!!document.getElementsByClassName('error-message-wrapper')[0].style.visibility) userTouched();
      }
  }

  function getMessage() {
    return parseToQueryString('message', qString);
  }

  function _getUserName() {
    return _storage.getItem('loginUserNameTemp') || '';
  }

  function _getUserPwd() {
    return _storage.getItem('loginUserPwdTemp') || '';
  }

  function _enableBtnSubmit() {
    btnSubmit.removeAttribute('class');
    btnSubmit.setAttribute('class', 'new-primary');

    btnSubmit.disabled = false;
  }

  function _disableBtnSubmit() {
    btnSubmit.removeAttribute('class');
    btnSubmit.setAttribute('class', 'new-secundary');

    btnSubmit.disabled = true;
  }

  function checkBtnSubmit() {
    const recaptchaCheck = verifyRecaptcha();
    if(inputUser.value && inputPwd.value && (recaptchaCheck || environment.apiUrl.includes('staging'))) {
      _enableBtnSubmit();
    } else {
      _disableBtnSubmit();
    }
  }

  function _enableBtnVerifySSO() {
    btnVerifySSO.removeAttribute('class');
    btnVerifySSO.setAttribute('class', 'new-primary');

    btnVerifySSO.disabled = false;
  }

  function _disableBtnVerifySSO() {
    btnVerifySSO.removeAttribute('class');
    btnVerifySSO.setAttribute('class', 'new-secundary');

    btnVerifySSO.disabled = true;
  }

  function checkBtnVerifySSO() {
    if(inputUserSSO.value) {
      _enableBtnVerifySSO();
    } else {
      _disableBtnVerifySSO();
    }
  }

  return { bindEvents, checkInputs, getMessage };
}

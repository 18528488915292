import { FormNoAjax } from '../_services/form-no-ajax'
import { setViewLogin } from '../_services/helpers'
import { $on } from '@services/globalHelpers'
import StatusCode from '../_services/status-code'
import LoginEventsGA from '../_services/google-analytics/index'
import SELECTOR from '../_services/selectors';

export default class Login {
  constructor() {
    this._loginEventsGA = new LoginEventsGA();
    this._element = document.querySelector(SELECTOR.CLASS_LOGIN_CONTENT);
  }

  start() {
    this.config();
    this._loginEventsGA.start();

    FormNoAjax().bindEvents();
    LoginPage().init();
    $on(window, 'load', setViewLogin);
  }

  config() {
    this._element.removeAttribute('style');
  }

  /**
   * @param {String} token: Token para acessar o Hugme
   * @param {String} code: statusCode
   */
  logIn({token, code}) {
    let params = {
      message: FormNoAjax().getMessage() || null,
      token: token || null
    };

    if(token && code) {
      params['token'] = token
    }

    if(!token && code) {
      delete params['token'];
    }

    StatusCode({code:code, data: params});
    FormNoAjax().checkInputs();
  }
}

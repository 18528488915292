import { createModal } from './dom/manipulator'

export default class ModalManipulator {
  constructor({content, id, config}) {
    this.content = content
    this.id = id

    this.modal = createModal({content: this.content, id: this.id, config: config})
  }

  open() {
    this.modal.open()
  }

  close() {
    this.modal.close()
  }

  getInstance() {
    return this.modal
  }

  getElement() {
    return this.modal._element
  }
}

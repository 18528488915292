const _HTMLSpinner = `
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
`

function parseToQueryString(name, url) {
  if (!url) url = location.href;
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  var regexS = "[\\?&]"+name+"=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
}

export function getType() {
  return parseToQueryString('type', window.location.search);
}

export function getToken() {
  return parseToQueryString('token', window.location.search);
}

export function getStatusCode() {
  return parseInt(parseToQueryString('statusCode', window.location.search));
}

export function getAccessToken() {
  return parseToQueryString('accessToken', window.location.search);
}

/**
 * querySelector wrapper
 *
 * @param {String} selector Selector to query
 * @param {Element} [scope] Optional scope element for the selector
 */
export function qs(selector, scope) {
	return (scope || document).querySelector(selector);
}

/**
 * addEventListener wrapper
 *
 * @param {Element|Window} target Target Element
 * @param {string} type Event name to bind to
 * @param {Function} callback Event callback
 * @param {boolean} [capture] Capture the event
 */
export function $on(target, type, callback, capture) {
  target.addEventListener(type, callback, !!capture);
}

/**
 * @param {HTMLElement} element
 */
 export const enableLoading = element => {
  _disableAllButtons()

  _addSpinner(element)
}

export const disableLoading = () => {
  _enableAllButtons()

  _removeSpinner()
}

/**
 * @param {HTMLElement} scope
 */
 const _disableAllButtons = scope => {
  (scope || document).querySelectorAll('button').forEach(button => {
    button.disabled = true
  })
}

/**
 * @param {HTMLElement} scope
 */
 const _enableAllButtons = scope => {
  (scope || document).querySelectorAll('button').forEach(button => {
    button.disabled = false
  })
}

/**
 * @param {HTMLElement} element
 */
const _addSpinner = element => element.insertAdjacentHTML('afterbegin', _HTMLSpinner);

/**
 * @param {HTMLElement} scope
 */
const _removeSpinner = scope => {
  (scope || document).querySelectorAll('button').forEach(button => {
    if(button.querySelector('.spinner-border')) {
      button.querySelector('.spinner-border').remove();
    }
  })
}

export const hideCloseButtonFromModal = modalId => {
  qs(`#${modalId} .hugmodal-header .close`).classList.add('hide');
}

export const showCloseButtonFromModal = modalId => {
  qs(`#${modalId} .hugmodal-header .close`).classList.remove('hide');
}

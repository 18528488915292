import { qs } from '@services/globalHelpers';

const SELECTOR = {
  CLASS_LOGIN_CONTENT: `.login-content`,
  LOGIN_INPUT_MAIL: qs('div.login-content input[id="user"]'),
  LOGIN_INPUT_PASSWORD: qs('div.login-content input[name="vo.senha"]'),
  LOGIN_CHECK_REMEMBER: qs(`div.login-content input[id='rememberData']`),
  LOGIN_ICON_CLEANEMAIL: qs(`div.login-content i[class='icon-close2']`),
  LOGIN_ICON_SHOWPASSWORD: qs(`div.login-content i[class='icon-eye']`),
  LOGIN_ICON_HIDEPASSWORD: qs(`div.login-content i[class='icon-eye-blocked']`),
  LOGIN_BUTTON_GOTOBLOG: qs(`div.login-content div.banner-detail button[id="see-more"]`),
  LOGIN_BUTTON_TRIAL: qs(`#cta_login`),
}

export default SELECTOR

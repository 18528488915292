import { HugPwdRevealer, HugTooltip } from '@hugcomponents/vanilla-js';
import { openModal } from './modal/dom/manipulator';
import PasswordRevealer from '../components/password-revealer/password-revealer';
import HugPwdStrength from '../components/hug-pwd-strength/src';
import ModalManipulator from './modal/index'

/**
 * @param {EventListenerObject} event
 * @returns
 */
export const inputNotAllowWhiteSpace = event => {
  if (event.keyCode === 32 || event.which === 32) {
    event.preventDefault();
    return;
  }
}

export const createInstanceModalOld = ({content, id, open}) => {
  let modalMustOpen = typeof open === 'boolean' ? open : true;

  if(modalMustOpen) {
    openModal({content, id, open});
  } else {
    return openModal({content, id, open});
  }
}

export const createInstanceModal = ({content, id, config}) => {
  return new ModalManipulator({ content:content, id:id, config:config })
}

/**
 * DEPRECATED
 * @param {String} element
 * @param {Object} options
 */
export const activatePasswordRevealer = ({element, options}) => {
  new PasswordRevealer(element, options);
}

/**
 * @param {String} element
 * @param {Object} options
 */
export const enablePasswordRevealer = ({element, options}) => {
  return new HugPwdRevealer(element, options)
}

/**
 * @param {String} element
 * @param {Object} options
 */
export const activatePasswordStrength = ({element, options}) => {
  return new HugPwdStrength(element, options);
}

export const enableTooltip = ({element}) => {
  return new HugTooltip(element);
}

export const $storage = () => {
  const get = (itemName) => {
    let item = localStorage.getItem(itemName);
    let jsonPatt = new RegExp(/[\[\{].*[\}\]]/);

    if(item) {
      if(jsonPatt.test(item)) return JSON.parse(item);

      return item;
    }
    else {
      return null;
    }
  };

  const set = (key, value) => {
    if(typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  }

  const remove = (key) => {
    localStorage.removeItem(key);
  };

  return { get, set, remove };
}

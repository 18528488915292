export const tpl = `
<div class="pwd-strength-popover right">
  <div class="pwd-strength-popover__arrow"></div>
  <div class="pwd-strength-popover__title">Sua senha deve ter:</div>
  <div class="pwd-strength-popover__body">
    <ul class="list">

      <li class="length-flag">
        <i class="pwd-icon-checkmark icon-checkmark"></i> 8 ou mais caracteres
      </li>

      <li class="upper-case-flag lower-case-flag upper-and-lower-case">
        <i class="pwd-icon-checkmark icon-checkmark"></i> Letras maiúsculas e minúsculas
      </li>

      <li class="digit-flag">
        <i class="pwd-icon-checkmark icon-checkmark"></i> Pelo menos um número
      </li>

      <li class="security-level">
        <i class="pwd-icon-checkmark icon-checkmark"></i> Nível Médio ou Forte de segurança
      </li>

    </ul>
    <small class="pwd-strength__caption">
    Não use informações pessoais,<br/>
    caracteres ou números em sequência e espaços<br/>
    em branco. exemplo: (123, abc, qwerty)
    </small>
  </div>
</div>
`;

const eventValue = 1;
const category = 'login';

export const LOGIN_INPUT_USER = {
  name: `inputmail`,
  params: {
    'event_category': category,
    'event_label': 'textfield',
    'event_action': 'inputmail',
    'value': eventValue
  }
}

export const LOGIN_INPUT_PASSWORD = {
  name: `inputpassword`,
  params: {
    'event_category': category,
    'event_label': 'textfield',
    'event_action': 'inputpassword',
    'value': eventValue
  }
}

export const LOGIN_CHECKBOX_REMEMBERDATA = {
  name: `checkrememberdata`,
  params: {
    'event_category': category,
    'event_label': 'checkbox',
    'event_action': 'checkrememberdata',
    'value': eventValue
  }
}

export const LOGIN_CHECKBOX_UNCHECKREMEMBERDATA = {
  name: `uncheckrememberdata`,
  params: {
    'event_category': category,
    'event_label': 'checkbox',
    'event_action': 'uncheckrememberdata',
    'value': eventValue
  }
}

export const LOGIN_ICON_CLEANINPUTMAIL = {
  name: `cleaninputmail`,
  params: {
    'event_category': category,
    'event_label': 'button-icon',
    'event_action': 'cleaninputmail',
    'value': eventValue
  }
}

export const LOGIN_ICON_SHOWPASSWORD = {
  name: `showpassword`,
  params: {
    'event_category': category,
    'event_label': 'button-icon',
    'event_action': 'showpassword',
    'value': eventValue
  }
}

export const LOGIN_ICON_HIDEPASSWORD = {
  name: `hidepassword`,
  params: {
    'event_category': category,
    'event_label': 'button-icon',
    'event_action': 'hidepassword',
    'value': eventValue
  }
}

export const LOGIN_SUCCESS = {
  name: `loginsuccess`,
  params: {
    'event_category': category,
    'event_label': 'form',
    'event_action': 'loginsuccess',
    'value': eventValue
  }
}

export const LOGIN_FAIL = {
  name: `loginfail`,
  params: {
    'event_category': category,
    'event_label': 'form',
    'event_action': 'loginfail',
    'value': eventValue
  }
}

export const LOGIN_BUTTON_GOTOBLOG = {
  name: `gotoblog`,
  params: {
    'event_category': category,
    'event_label': 'button',
    'event_action': 'gotoblog',
    'value': eventValue
  }
}

export const LOGIN_BUTTON_TRIAL = {
  name: `login_clickqueroconhecer_button`,
  params: {
    'event_category': category,
    'event_label': 'button',
    'event_action': 'clickqueroconhecer',
    'value': eventValue
  }
}

export const Labels = {
  URL_RECAPTCHA: 'https://www.google.com/recaptcha/api.js',
  TEXT_USER_INCORRECT: `Seu e-mail ou senha estão incorretos, por favor,
  tente novamente. Se esqueceu a senha, clique abaixo em “Esqueci a minha senha”.`,
  TEXT_EMAIL_ERROR: `Erro no envio do e-mail.<br/>
  Clique novamente no botão abaixo.`
};

export const options = {
  inputPassword: {
    eye: {
      selector: '#revealer-one',
      iconHide: '.icon-eye-blocked',
      iconShow: '.icon-eye'
    }
  },
  inputConfirmPassword: {
    eye: {
      selector: '#revealer-two',
      iconHide: '.icon-eye-blocked',
      iconShow: '.icon-eye'
    }
  }
};


export default class PasswordRevealer {
  /**
   * @param {HTMLElement} trigger
   * @param {Object} options
   */
  constructor(trigger, options) {
    if (!trigger) {
      throw new Error('Missing input argument');
    }

    if (typeof trigger === 'string') {
      this.input = document.querySelector(trigger);
    }

    this.options = options;
    this.setupOptions();
  }

  setupOptions() {
    if (!this.input || !this.input.nodeType) {
      throw new Error('Trigger must be a HTML element');
    }

    if (this.input.nodeName !== 'INPUT') {
      throw new Error('First argument (input) must be an input element');
    }

    this.isRevealed = this.options.isRevealed || false;
    this.setupEyeIcon(this.options.eye);
    this.options.eye.selector.addEventListener('click', (event) => {
      event.preventDefault();
      this.toggle();
    }, false);
  }

  setupEyeIcon({selector, iconHide, iconShow}) {

    if (!selector) {
      throw new Error('[Eye Element]: Missing input argument')
    }

    if(typeof selector === 'string') {
      this.options.eye.selector = document.querySelector(selector);
    }

    if(!iconHide || !iconShow) {
      throw new Error('[Eye Element]: Enter icon elements');
    }

    this.options.eye.iconHide = this.options.eye.selector.querySelector(iconHide);
    this.options.eye.iconShow = this.options.eye.selector.querySelector(iconShow);

    this.options.eye.iconHide.style.display = 'none';
    this.css(this.options.eye.selector, {
      'position': 'absolute',
      'z-index': '999'
    });
  }

  show() {
    this.input.type = 'text';
    this.isRevealed = true;

    this.options.eye.iconShow.style.display = 'none';
    this.options.eye.iconHide.style.display = 'inline-block';
  }

  hide() {
    this.input.type = 'password';
    this.isRevealed = false;

    this.options.eye.iconHide.style.display = 'none';
    this.options.eye.iconShow.style.display = 'inline-block';
  }

  toggle() {
    this.isRevealed ? this.hide() : this.show();
  }

  css(element, style) {
    for (let property in style)
      element.style[property] = style[property];
  }
}

import LoginEventsGA from './google-analytics/index';
import { Labels } from '../models/hug-login';
import { $storage } from '@services/globalFactories';
import TYPES from '../_services/types';
import * as Util from './util';

const loginEventsGA = new LoginEventsGA()

const changeTextButton = () => {
  LoginPage().changeTextButton('Entrar');
}

/**
 * @param {Number} code
 * @param {Object} data
 */
const StatusCode = async ({ code, data}) => {
  if(code === 201) {
    if(!data) return 'O status code 201 precisa ter parâmetros de entrada';

    if(typeof data !== 'object') return 'O parametro (data) precisa ser um objeto';

    // No ajax
    if(data.type === TYPES.TOKEN_HUGME) {
      changeTextButton()
    }

    if ($storage().get('rememberData')) {
      var userData = $storage().get('loginData');
      var loginUserName = $storage().get('loginUserNameTemp')
      var loginUserPwd = $storage().get('loginUserPwdTemp')

      if(userData) {
        if(loginUserName && loginUserName !== userData.email) {
          Util.storageUserdata(loginUserName,loginUserPwd);
        }

        if(loginUserPwd && loginUserPwd !== userData.pass){
          Util.storageUserdata(loginUserName,loginUserPwd);
        }
      }

      if(!userData) {
        Util.storageUserdata($storage().get('loginUserNameTemp'),$storage().get('loginUserPwdTemp'));
      }
    } else {
      $storage().remove('loginData');
    }

    $storage().remove('loginUserNameTemp');
    $storage().remove('loginUserPwdTemp');
    $storage().set('hugToken', data.token);

    loginEventsGA.loginSuccess();
    Util.redirectIntoHugme();
    return;
  }

  if(data.message === 'sso-activated') {
    loginEventsGA.loginFail();
    LoginPage().errorSsoActivated();
    return;
  }

  if(code === 401 && data.message === 'incorrectUser') {
    loginEventsGA.loginFail();
    LoginPage().errorUnauthorized({ message: Labels.TEXT_USER_INCORRECT });
    return;
  }

  if(code >= 400 && code < 500 && data.message) {
    loginEventsGA.loginFail();
    LoginPage().error({ message:Util.decodeString(data.message) });
    return;
  }

  if(code === 500) {
    loginEventsGA.loginFail();
    LoginPage().error({ message:'Ocorreu um erro ao autenticar. Tente novamente mais tarde.' });
    return;
  }
}

export default StatusCode

export default class GoogleAnalytics {
  constructor(){
    this.dictionary = {
      event_category: `eventCategory`,
      event_label: `eventLabel`,
      event_action: `eventAction`,
      value: `eventValue`,
    };
  }

  sendEvent({name, params}) {
    const id_organizacao = window.pessoa && window.pessoa.organizacao && window.pessoa.organizacao.id ? window.pessoa.organizacao.id : null;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: name, ...params, id_organizacao })
  }
}

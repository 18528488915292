import { getToken, enableLoading, disableLoading } from '../_services/helpers';
import axiosInstance from '@services/apiInterceptor';
import { options } from '../models/hug-login';
import { validatePasswords } from './_services/util';
import statusCode from './_services/status-code'
import SELECTOR from '../_services/selectors';
import {
  inputNotAllowWhiteSpace,
  activatePasswordRevealer,
  activatePasswordStrength } from '@services/globalFactories';
import { qs, $on } from '@services/globalHelpers';
import * as Util from '../_services/util';

// HTMLs
import Template from './template.html';

const MODAL_MAIN = 'modalContainerService';
const INPUT_PASSWORD = `#input-password`;
const INPUT_PASSWORD_CONFIRM = `#input-password-confirm`;
const OPTIONS_PASSWORD_INPUT = [{
  element: INPUT_PASSWORD,
  options: options.inputPassword
}, {
  element: INPUT_PASSWORD_CONFIRM,
  options: options.inputConfirmPassword
}];

export function PasswordCreation(scope) {
  'use strict';

  if (!scope) {
    throw new Error('Missing input argument');
  }

  const content = qs(SELECTOR.CLASS_LOGIN_CONTENT, scope);
  content.innerHTML = Template;

  setTimeout(()=> {
    content.removeAttribute('style');
  },300)

  const $btnCancelNewPwd = qs('#btn-cancel-new-password');
  const $btnCreatePassword = qs('#btn-create-password');
  const $inputPasswordConfirm = qs(INPUT_PASSWORD_CONFIRM);
  const $inputPassword = qs(INPUT_PASSWORD);

  const token = getToken();

  let pwdStrength;

  const start = () => {
    Util.removeClassScope({ scope:scope, className:'login' });
    Util.addClassScope({ scope:scope, className:'page-password-creation' });

    OPTIONS_PASSWORD_INPUT.forEach(item => {
      activatePasswordRevealer({ element: item.element, options: item.options });
    });

    checkTokenStatus(token).then(status => {
      if(status) {
        pwdStrength = activatePasswordStrength({
          element: INPUT_PASSWORD,
          options: {}
        });
      }
      bindEvents();
    });
  };

  const bindEvents = () => {
    // Button event
    $on($btnCreatePassword, 'click', ({currentTarget}) => createPassword(currentTarget));
    $on($btnCancelNewPwd, 'click', backToLoginPage);

    // Input event
    $on($inputPasswordConfirm, 'keyup',(event) => {
      if (event.keyCode !== 9) checkInputPasswords();
    });
    $on($inputPasswordConfirm, 'keypress',(event) => inputNotAllowWhiteSpace(event));

    if(pwdStrength) {
      pwdStrength.on('on:keyup', (e) => {
        if($inputPasswordConfirm.value !== ''){
          checkInputPasswords();
        } else {
          $btnCreatePassword.disabled = validatePagePasswords();
        }
      });
    }
  };

  const backToLoginPage = () => {
    window.location.href = '/';
  }

  const createPassword = (element) => {
    enableLoading(element);
    axiosInstance.post('/api/public/passwordCreate.action',{
      token: token,
      password: $inputPassword.value,
      passwordConfirm: $inputPasswordConfirm.value
    }).then((res) => {
      disableLoading();
      const status = res.status;

      statusCode(status);

      if(status === 201) {
        _closeModalAndBackToLogin(MODAL_MAIN);
      }

      if(status === 208) {
        _closeModalAndBackToLogin(MODAL_MAIN);
      }
    }).catch((error) => {
      disableLoading();
      const status = error.response.status;

      statusCode(status);

      if(status === 403) {
        _closeModalAndBackToLogin(MODAL_MAIN);
      }
    });
  };

  const checkInputPasswords = () => {
    const $groupConfirm = qs('.password-group-confirm');

    if($inputPasswordConfirm.value !== $inputPassword.value) {
      $groupConfirm.classList.add('has-error');
    } else {
      $groupConfirm.classList.remove('has-error');
    }

    $btnCreatePassword.disabled = validatePagePasswords();
  };

  /**
   * @param {String} token
   * @returns Boolean
   */
  const checkTokenStatus = async (token) => {
    let statusToken = false;
    if(token) {
      try {
        const { status } = await axiosInstance.get(`/api/auth/jwt/validate?token=${token}`);

        statusCode(status);

        if(status === 200) {
          statusToken = true;
        }

        if(status === 208) {
          _closeModalAndBackToLogin(MODAL_MAIN);
        }
        return statusToken;
      } catch (error) {
        statusToken = false;
        const status = error.response.status;

        statusCode(status);

        if(status === 401) {
          _closeModalAndBackToLogin(MODAL_MAIN);
        }

        if(status === 403) {
          _closeModalAndBackToLogin(MODAL_MAIN);
        }

        return statusToken;
      }
    }
  };

  const _closeModalAndBackToLogin = id => {
    $(`#${id}`).on('hidden.bs.modal', function (e) {
      window.location.href = '/';
    });
  };

  const validatePagePasswords = () => {
    return validatePasswords({
      objPasswordStrength:pwdStrength,
      input: $inputPassword,
      inputConfirm: $inputPasswordConfirm
    });
  }

  return { start };
}

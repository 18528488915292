export const modalSuccess = `
<div class="hugmodal-dialog hugmodal-dialog-centered" role="document">
  <div class="hugmodal-content">
    <div class="hugmodal-header without-header">
      <div></div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="hugmodal-body">
      <span class="icon-mail"></span>
      <div class="mb-3"><strong>E-mail enviado!</strong></div>
      <p class="mb-4">
        <span>Nós enviamos um e-mail com o link de criação da sua senha. </span>
      </p>
      <p class='alert alert-danger mb-4 m-auto' style="width: 350px;">O link expira em 15 minutos </p>
    </div>
  </div>
</div>`;

import Emitter from 'tiny-emitter';
import zxcvbn from 'zxcvbn';
import { PwdStrengthWrapper } from './wrapper';
import { Popover } from './popover/popover';

const Default = {
  containerId: 'pwdstr-container',
  popoverContainer: 'pwd-strength-popover',
  textDangerElement: 'pwdstr-input__textdanger',
  progressElement: 'pwdstr-progress',
  popover: '',
  minPasswordLength: 8,
  placement: 'top',
  template:`
  <div class="pwdstr-input">
    <div class="pwdstr-input__textdanger">
      A senha não atende aos requisitos mínimos.
    </div>
    <div class="pwdstr-input__body">
      <div class="pwdstr-input__item">
        <span class="pwdstr-input__item__legend">Nível de segurança</span>
      </div>
      <div class="pwdstr-input__item">
        <div class="pwdstr-progress">
          <div class="pwdstr-progress__text"><span>Nenhum</span></div>
          <div class="pwdstr-progress__level">
            <div class="pwdstr-progress__bar pwdstr-progress__bar-danger" style="width: 29%"></div>
            <div class="pwdstr-progress__bar pwdstr-progress__bar-warning" style="width: 29%"></div>
            <div class="pwdstr-progress__bar pwdstr-progress__bar-success" style="width: 29%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="section-popover"></div>`,
  trigger: {
    selector: '',
    eventListener: ['keyup']
  }
};

export default class HugPwdStrength extends Emitter {
  /**
   * @param {HTMLElement} trigger
   * @param {Object} config
   */
  constructor(element, config) {
    if (typeof zxcvbn === 'undefined') {
      throw new TypeError('Password Strength require zxcvbn (https://github.com/dropbox/zxcvbn)');
    }

    if (!element) {
      throw new Error('Missing input argument');
    }

    super();

    this.pwdStrengthResult = null;
    this._popper = null;
    this._config = this._getConfig(config);
    this._element = this._getElement(element);
    this._wrapper = PwdStrengthWrapper(this._config);

    this.resolveOptions();
  }

  static get Default() {
    return Default
  }

  resolveOptions() {
    if (!this._element || !this._element.nodeType) {
      throw new Error('Trigger must be a HTML element')
    }

    if (this._element.nodeName !== 'INPUT') {
      throw new Error('First argument (input) must be an input element');
    }

    this.bindEvents();
  }

  bindEvents() {
    this._element.addEventListener('focus', ({target}) => {
      this.show(target);
    }, false);

    // Not allow white space
    this._element.addEventListener('keypress', (event) => {
      if (event.keyCode === 32 || event.which === 32) {
        event.preventDefault();
        return;
      }
    }, false);

    ['keyup', 'blur'].forEach(type => {
      this._element.addEventListener(type, (event) => {
        this.checkPassword(event);
        this.emit(`on:${event.type}`, event);
      }, false);
    });
  }

  checkPassword(event) {
    if (event.keyCode === 32 || event.which === 32) {
      event.preventDefault();
      return;
    }

    const target = event.currentTarget;
    const value  = target.value;

    const container = target.parentNode.querySelector(`#${this._config.containerId}`);
    const progress = container.querySelector(".pwdstr-progress");
    const text = progress.querySelector(".pwdstr-progress__text span");

    let result = { str: {}, pwd: {} };

    if(value) {
      result.str = this._checkString(value);
      result.pwd = zxcvbn(value);
      result.pwd.highLevelSecurity = true;

      this.pwdStrengthResult = result;

      // weak password
      if( (result.pwd.score === 0 || result.pwd.score === 1) ||
        result.pwd.score === 2 && parseInt(result.pwd.guesses_log10) < 8) {
        text.innerHTML = "Fraca";
        result.pwd.highLevelSecurity = false;
      }

      // medium password
      if( (result.pwd.score === 2 &&
        parseInt(result.pwd.guesses_log10) >= 8) ||
        result.pwd.score === 3) {
        text.innerHTML = "Médio";
      }

      // strong password
      if(result.pwd.score === 4) {
        text.innerHTML = "Forte";
      }

      this._runWrapper(container, result);
    } else {
      text.innerHTML = "Nenhum";
      result.pwd.score = -1;
      this._runWrapper(container, result);
    }
  }

  show(target) {
    const parentNode = target.parentNode;
    let container = parentNode.querySelector(`#${this._getContainerId()}`);

    if(!container) {
      container = document.createElement("div");
      container.innerHTML = this._config.template;
      container.setAttribute("id", this._getContainerId());
      parentNode.insertBefore(container, target.nextSibling);

      this._popper = Popover({
        target: target,
        container: container,
        parentContainer: 'section-popover'
      });
      this._popper.init();

      // Input
      parentNode.querySelector(".pwdstr-input").style.display = `block`;
      parentNode.querySelector(".pwdstr-input").style.position = `absolute`;
    }
  }

  /**
   * @param {HTMLElement} container
   * @param {Object} result
   */
  _runWrapper = (container, result) => {
    this._wrapper.setData(container, result);

    this._wrapper.minRequirements();

    this._wrapper.progress();

    this._popper.update(result);
  }

  _checkString(str) {
    var lowerCaseRegex = RegExp('(?=.*[a-z])');
    var upperCaseRegex = RegExp('(?=.*[A-Z])');
    var digitRegex = RegExp('(?=.*\\d)');
    var lowerCaseFlag = lowerCaseRegex.test(str);
    var upperCaseFlag = upperCaseRegex.test(str);
    var digitFlag = digitRegex.test(str);
    var lengthFlag = str.length >= this.constructor.Default['minPasswordLength'];

    return { lowerCaseFlag, upperCaseFlag, digitFlag, lengthFlag };
  }

  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...(typeof config === 'object' && config ? config : {})
    };

    return config;
  }

  _getContainerId() {
    return this.constructor.Default['containerId'];
  }

  _getElement(element) {
    return typeof element === 'string' ? document.querySelector(element) : element;
  }
}

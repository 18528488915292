import HugModalOld from '../modal';
import { HugModal } from '@hugcomponents/vanilla-js';

const Default = {
  keyboard: true,
  backdrop: true
};

const _getConfig = config => {
  config = {
    ...Default,
    ...(typeof config === 'object' && config ? config : {})
  }

  return config
}

export function openModal({content, id, open}) {
  if(!content) {
    throw new Error('Missing modal content');
  }

  let modalMustOpen = typeof open === 'boolean' ? open : true;
  let div = document.createElement('div');

  div.classList.add('hugmodal', 'fade');
  div.setAttribute('id', id);
  div.setAttribute('tabindex', '-1');
  div.setAttribute('data-keyboard', 'false');
  div.setAttribute('data-backdrop', 'static');

  if(!modalMustOpen) {
    div.classList.add('hide');
  }

  div.innerHTML = content;
  document.body.appendChild(div);

  const modal = new HugModalOld(id);

  if(modalMustOpen) {
    modal.open();
  } else {
    return { element:div, modal:modal };
  }
};

export function createModal({content, id, config}) {
  if(!content) {
    throw new Error('Missing modal content');
  }
  let div = document.createElement('div');

  div.classList.add('hugmodal','fade');
  div.setAttribute('id', id);
  div.setAttribute('tabindex', '-1');
  div.setAttribute('data-bs-keyboard', 'false');
  div.setAttribute('data-bs-backdrop', 'static');

  const dataAttributes = _getConfig(config);

  if(!dataAttributes.keyboard) div.removeAttribute('data-bs-keyboard');
  if(!dataAttributes.backdrop) div.removeAttribute('data-bs-backdrop');

  div.innerHTML = content;
  document.body.appendChild(div);

  const modal = new HugModal(document.querySelector(`#${id}`));

  modalConfig(modal._element);
  return modal;
};

const modalConfig = element => {
  element.addEventListener('hidden.bs.modal', function (event) {
    event.target.remove()
  })
}

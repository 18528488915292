
export const Event = {
  CLOSE_MODAL_CHECKUSER: 'close:modalCheckUser2FA',
  CLOSE_MODAL_CONTINUETOCODE: 'close:modalContinueToCode2FA',
  CLOSE_MODAL_CHECKCODE: 'close:modalCheckCode2FA',
  CLOSE_MODAL_DISABLE: 'close:modalDisable2FA',
  ENABLED_2FA: '2FA:ENABLED',
  DISABLED_2FA: '2FA:DISABLED',
}

export const inputPwdUser = {
  element: '#input-password-revealer-2fa',
  config: {
    eye: {
      selector: '#revealer-one',
      iconHide: '#icon-eye-blocked-2fa',
      iconShow: '#icon-eye-2fa'
    }
  }
}

export const Labels = {
  MODAL_2FA_LOGO_HUGME: '/img/rodape-logo-ra.png',
  MODAL_2FA_TITLE_ACTIVATE: 'Ative o Duplo Fator de Autenticação',
  MODAL_2FA_CONTENT_ACTIVATE: `Para a confirmação do 2FA, insira a senha<br/>
  cadastrada no HugMe. Um código será enviado<br/>
  para o seu e-mail e deve ser inserido na próxima tela.`,
  MODAL_2FA_ACTIVATED_TITLE: `Duplo Fator de Autenticação ativado!`,
  MODAL_2FA_ACTIVATED_CONTENT: `Parabéns! Agora sua empresa está mais segura<br/>
  com o 2FA do HugMe. Em caso de dúvidas, nosso suporte<br/>
  está à disposição para ajudá-los.`,
  MODAL_2FA_DISABLE_TITLE: `Desative o Duplo Fator de Autenticação`,
  MODAL_2FA_DISABLE_CONTENT: `Deseja desabilitar o adicional de segurança fator<br/>
  de dupla autenticação?`,
  MODAL_2FA_DISABLE_TEXT_WARNING: `Caso prossiga com a ação de desabilitar o 2FA, você precisará<br/>realizar o processo de autenticação de dois fatores novamente.`,
  MODAL_2FA_CONFIRM_CODE_CONTENT: `Agora é só inserir o código recebido no<br/>
  e-mail cadastrado e verificá-lo.`,
  MODAL_2FA_CONFIRM_DISABLE_TITLE: `Duplo Fator de Autenticação desativado!`,
  MODAL_2FA_CONFIRM_DISABLE_CONTENT: `Agora a sua empresa está com o duplo fator de <br/>
  autenticação do HugMe desativado. Em caso de dúvidas,<br/>
  nosso suporte está à disposição para ajudá-los.`,
  MODAL_2FA_TEXT_USERINVALID: `Senha incorreta. Por favor, tente novamente.<br/>
  Se esqueceu a senha, vá para<br/> "esqueci a senha" na tela de login.`,
  MODAL_2FA_BTN_CHECK: 'Verificar',
  MODAL_2FA_BTN_RESENDCODE: 'Reenviar código',
  MODAL_CONTINUETOCODE_TITLE: `Código enviado`,
  MODAL_CONTINUETOCODE_CONTENT: `Um código foi enviado para o seu e-mail<br/>
  e deve ser inserido na próxima tela.`,
  MODAL_CONTINUETOCODE_CONTENT_RESEND: `Um código foi reenviado para o seu e-mail<br/>
  e deve ser inserido na próxima tela.`,
  MSG_CODE_EXPIRED: `Código expirado.<br>
  Por favor, clique no botão abaixo e solicite o seu reenvio.`,
  MSG_CODE_INCORRECT: `Código incorreto.<br>
  Por favor, clique no botão abaixo e solicite o seu reenvio.`,
  MSG_CODE_INVALID: `Código inválido.<br/>
  Por favor, insira o código correto ou clique no botão<br/>
  Reenviar Código para receber um novo código no seu e-mail.`,
  MSG_CODE_RESEND: `Código reenviado.<br/>
  Confira novamente o seu e-mail e reinsira o código.`,
  MSG_INCORRECT_PASSWORD_USER: `Senha incorreta. Por favor, tente novamente.<br/>Se esqueceu a senha, vá para<br/>"esqueci a senha" na tela de login.`,
  TITLE_CODE_RESEND: `Código Reenviado`
};

export const IDs = {
  MODAL_MAIN_ID: 'modalContainerService2FA',
  MODAL_CODESENT_ID: 'modalCodeSent2FA',
  MODAL_ACTIVATED_ID: 'modalActivatedCodet2FA',
  MODAL_CONFIRM_DISABLE_ID: 'modalConfirmDisable2FA',
  MODAL_DISABLE_ID: 'modalDisable2FA',
  MODAL_CHECKCODE_ID: 'modalConfirmCode2FA'
};

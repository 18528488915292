import * as Factory from '@services/globalFactories';

export const userEmail = document.getElementById('user');
export const userPassword = document.getElementById('password');

export const redirectIntoHugme = () => {
  let urlContinue = Factory.$storage().get('hugContinue');
  let redirectTo = urlContinue !== null ? urlContinue : 'app.html';

  window.location.href = redirectTo;
}

export const addClassScope = ({scope, className}) => {
  scope.classList.add(className);
}

export const removeClassScope = ({scope, className}) => {
  scope.classList.remove(className);
}

export const redirectInto2FA = () => {
  window.location.href = '?type=page-login-2fa';
}

export const decodeString = (str) => {
  return unescape(str);
}

export const storageUserdata = (userValue, pwdValue) => {
  var loginData = { email: userValue, pass: btoa(pwdValue) };
  Factory.$storage().set('loginData', JSON.stringify(loginData));
}

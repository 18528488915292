import { createInstanceModalOld } from '../../../../services/globalFactories';
import modalInvalidToken from '../modals/invalidToken.html';
import modalTokenAlreadyUsed from '../modals/tokenAlreadyUsed.html';
import modalError from '../modals/error.html';
import modalExpiredLink from '../modals/expiredLink.html';
import modalSuccess from '../modals/success.html';

const MODAL_MAIN = `modalContainerService`;

const statusCode = code => {

  if(code === 201) {
    createInstanceModalOld({content:modalSuccess, id:MODAL_MAIN});
  }

  if(code === 208) {
    createInstanceModalOld({content:modalTokenAlreadyUsed, id:MODAL_MAIN});
  }

  if(code === 401) {
    createInstanceModalOld({content:modalInvalidToken, id:MODAL_MAIN});
  }

  if(code === 403) {
    createInstanceModalOld({content:modalExpiredLink, id:MODAL_MAIN});
  }

  if(code === 500) {
    createInstanceModalOld({content:modalError, id:MODAL_MAIN});
  }
}

export default statusCode;

export const validatePasswords = ({objPasswordStrength, input, inputConfirm}) => {

  // segurança alto nível
  let highLevelSecurity = objPasswordStrength.pwdStrengthResult.pwd.highLevelSecurity;

  // Requisitos mínimos
  let pwdStrengthResult = objPasswordStrength.pwdStrengthResult.str;

  let disabledSaveButton = true;

  let FilterMinRequirements = Object.keys(pwdStrengthResult)
    .filter(item => pwdStrengthResult[item] === false);

  if(FilterMinRequirements.length === 0
    && input.value === inputConfirm.value) {
    disabledSaveButton = false;
  }

  if(input.value === null && inputConfirm.value === null) {
    disabledSaveButton = true;
  }

  if(input.value === '' && inputConfirm.value === '') {
    disabledSaveButton = true;
  }

  if(!highLevelSecurity) {
    disabledSaveButton = true;
  }

  return disabledSaveButton;
}

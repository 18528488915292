// Dependence jQuery

export default class HugModalOld {
  constructor(elementId) {

    this.defaults = {
      wrapper: '',
      headerTitle: 'Modal title',
      contentHTML: '',
      hasHeader: true,
      modalId: 'modalContainerService'
    };

    this._id = elementId;
    this.options = {};
  }

  bindEvents() {
    $(`#${this._id}`).on('hidden.bs.modal', function (e) {
      // IE11 support
      document.body.removeChild(e.currentTarget);
    });
  }

  open() {
    $(`#${this._id}`).modal();

    this.bindEvents();
  }

  close() {
    $(`#${this._id}`).modal('hide');
  }

  handleUpdate() {
    $(`#${this.options.modalId}`).modal('handleUpdate');
  }

  replaceContent(content) {
    this.options.wrapper.querySelector('.modal-body').innerHTML = content;
  }
}

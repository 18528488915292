
import { $on } from '@services/globalHelpers';
import GoogleAnalytics from '@services/googleAnalytics';
import SELECTOR from '../selectors';
import * as events from './events';

export default class LoginEventsGA {
  constructor() {
    this._ga = new GoogleAnalytics();
  }

  start() {
    this.inputMailAndInputPassword();
    this.checkboxRemember();

    this.iconCleanInputMail();
    this.iconHideAndShowPassword();
    this.buttonGoToBlog();
    this.buttonTesteGratis();
  }

  inputMailAndInputPassword() {
    [SELECTOR.LOGIN_INPUT_MAIL, SELECTOR.LOGIN_INPUT_PASSWORD].forEach( (item) => {
      $on(item, 'click', () => {
        let id = item.id.toUpperCase();

        this._ga.sendEvent({
          name: events[`LOGIN_INPUT_${id}`].name,
          params: events[`LOGIN_INPUT_${id}`].params
        })
      });
    })
  }

  checkboxRemember() {
    $on(SELECTOR.LOGIN_CHECK_REMEMBER, 'click', () => {
      if(SELECTOR.LOGIN_CHECK_REMEMBER.checked) {
        let id = SELECTOR.LOGIN_CHECK_REMEMBER.id.toUpperCase();

        this._ga.sendEvent({
          name: events.LOGIN_CHECKBOX_REMEMBERDATA.name,
          params: events.LOGIN_CHECKBOX_REMEMBERDATA.params
        })
      }

      if(!SELECTOR.LOGIN_CHECK_REMEMBER.checked) {
        this._ga.sendEvent({
          name: events.LOGIN_CHECKBOX_UNCHECKREMEMBERDATA.name,
          params: events.LOGIN_CHECKBOX_UNCHECKREMEMBERDATA.params
        })
      }
    });
  }

  iconCleanInputMail() {
    $on(SELECTOR.LOGIN_ICON_CLEANEMAIL, 'click', () => {
      this._ga.sendEvent({
        name: events.LOGIN_ICON_CLEANINPUTMAIL.name,
        params: events.LOGIN_ICON_CLEANINPUTMAIL.params
      })
    });
  }

  iconHideAndShowPassword() {
    $on(SELECTOR.LOGIN_ICON_SHOWPASSWORD, 'click', () => {
      this._ga.sendEvent({
        name: events.LOGIN_ICON_SHOWPASSWORD.name,
        params: events.LOGIN_ICON_SHOWPASSWORD.params
      })
    });

    $on(SELECTOR.LOGIN_ICON_HIDEPASSWORD, 'click', () => {
      this._ga.sendEvent({
        name: events.LOGIN_ICON_HIDEPASSWORD.name,
        params: events.LOGIN_ICON_HIDEPASSWORD.params
      })
    });
  }

  buttonGoToBlog() {
    $on(SELECTOR.LOGIN_BUTTON_GOTOBLOG, 'click', () => {
      this._ga.sendEvent({
        name: events.LOGIN_BUTTON_GOTOBLOG.name,
        params: events.LOGIN_BUTTON_GOTOBLOG.params
      })
    });
  }

  buttonTesteGratis() {
    $on(SELECTOR.LOGIN_BUTTON_TRIAL, 'click', () => {
      this._ga.sendEvent({
        name: events.LOGIN_BUTTON_TRIAL.name,
        params: events.LOGIN_BUTTON_TRIAL.params
      })
    });
  }

  loginSuccess() {
    this._ga.sendEvent({
      name: events.LOGIN_SUCCESS.name,
      params: events.LOGIN_SUCCESS.params
    })
  }

  loginFail() {
    this._ga.sendEvent({
      name: events.LOGIN_FAIL.name,
      params: events.LOGIN_FAIL.params
    })
  }
}

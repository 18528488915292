import ReCaptcha from '../../../../services/recaptcha';
import BroadcastChannel from '../../../../services/broadcastChannel';

export function reCAPTCHA() {
  'use strict'

  const broadcastChannel = new BroadcastChannel();
  const captcha = new ReCaptcha({
    type: 'v2',
    onload: 'loadRecaptcha',
    sitekey: environment.secret,
    'callback': 'callbackRecaptchaForgot',
    'expiredCallback': 'recaptchaExpiredForgot'
  });

  window.recaptchaExpiredForgot = () => {
    captcha.setToken(null);
    broadcastChannel.hugEmit({ name:'RecaptchaExpired', data:'OK'});
  };

  window.callbackRecaptchaForgot = (response) => {
    if(response) {
      captcha.setToken(response);
      broadcastChannel.hugEmit({ name:'RecaptchaResolved', data:'OK'});
    }
  };

  window.loadRecaptcha = function(){
    grecaptcha.render('recaptchaForgot', captcha.getConfigRender());
  };

  captcha.add();

  const getToken = () => {
    return captcha.getToken();
  };

  const reset = () => {
    captcha.setToken(null);
    grecaptcha.reset();
  };

  const captchaResolved = () => {
    return new Promise(resolve => {
      resolve(broadcastChannel);
    });
  }

  return { getToken, reset, captchaResolved }
}

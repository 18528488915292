const Default = {
  url:'https://www.google.com/recaptcha/api.js',
  tagId: 'sreCAPTCHA',
  sitekey: '',
  type: ''
}
export default class ReCaptcha {
  constructor(config) {
    if (!config) {
      throw new Error('Missing input argument');
    }

    this.onload = config.onload;
    this.callback = config.callback;
    this.onRecaptchaExpired = config.expiredCallback;

    this.token = '';
    this._config = this._getConfig(config);
  }

  static get Default() {
    return Default
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  add() {
    let tagScript = document.querySelector(`script[id='${this._config.tagId}']`);

    if(!tagScript) {
      const params = { render: this._config.sitekey };

      if(this._config.type === "v2") {
        params.render = "explicit";
        params["onload"] = this.onload;
      }

      var query = Object.keys(params)
      .map(function(k) {return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);})
      .join('&');

      this.loadScriptAsync(`${this.constructor.Default['url']}?${query}`);
    }
  }

  loadScriptAsync(uri) {
    var tag = document.createElement('script');
    var firstScriptTag = document.getElementsByTagName('script')[0];
    tag.src   = uri;
    tag.async = true;
    tag.defer = true;
    tag.id    = this._config.tagId;
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  getConfigRender() {
    return {
      'sitekey': this._config.sitekey,
      'callback' : this.callback,
      'expired-callback': this.onRecaptchaExpired
    };
  }

  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...(typeof config === 'object' && config ? config : {})
    };

    return config;
  }

}

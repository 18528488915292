export const Template = `<nav class="navbar navbar-light bg-light shadow-sm">
  <a class="navbar-brand mt-3" href="/">
    <img src="/img/rodape-logo-ra.png" width="120" alt="Logo hugme">
  </a>
</nav>
<section>
  <form class="form-password password-forgot" autocomplete="off">
    <div class="form-group">
      <label class="mb-3" for="inputEmail">Esqueceu a senha?</label>
      <div class="form-text text-muted mb-3">Insira abaixo o seu e-mail ativo para receber instruções e criar uma nova senha</div>
      <input type="email" placeholder="E-mail" class="form-control" id="input-email" aria-describedby="emailHelp">
      <div class="msg-password-confirm help-block mt-2 mb-3"></div>
      <small class="form-text text-muted mt-3">
        Lembra a senha? <a href="/">Login</a>
      </small>
    </div>
    <div class="mt-4">
      <div class="mb-3" id="recaptchaForgot"></div>
      <button type="button" class="button button-secondary" id="btn-cancel-forgot">Cancelar</a>
      <button type="button" class="button button-success ml-2" id="btn-send-email">Enviar</button>
    </div>
  </form>
</section>`;


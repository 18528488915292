import axiosInstance from '@services/apiInterceptor';

export const sendCodeToEmail = async token => {
  return await axiosInstance.post('/api/public/otp/send.action',{token:token});
}

export const auth = async ({code, token}) => {
  const res = await axiosInstance.post('/api/public/otp/authenticate.action',
    { otp:code, token:token }
  );

  return res;
}

import Emitter from 'tiny-emitter'

export default class BroadcastChannel extends Emitter {
  constructor() {
    super()
  }

  hugEmit({name, data}) {
    this.emit(`hugEvent:${name}`, data);
  }
}

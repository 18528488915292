import axios from 'axios';
import { getHugToken } from './auth';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    const customConfig = config;
    const token = getHugToken();

    if (token) {
      customConfig.headers.token = token;
    }

    if (!config.headers['Content-Type']) {
      customConfig.headers['Content-Type'] = 'application/json';
    }

    return customConfig;
  },
  error => Promise.reject(error),
);

export default axiosInstance;

import { qs, $on, enableLoading, disableLoading } from '../_services/helpers';
import { reCAPTCHA } from './_services/reCAPTCHA';
import axiosInstance from '../../../services/apiInterceptor';
import { modalSuccess } from './modals/emailSuccessForgot.js';
import { Template } from './template.js';
import { createInstanceModalOld } from '../../../services/globalFactories';
import * as Util from '../_services/util';
import SELECTOR from '../_services/selectors';

const MODAL_MAIN = 'modalContainerService';
const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&''*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
const MSG_INVALID_EMAIL = `Informe um e-mail válido.`;
const MSG_INVALID_RECAPTCHA = `Informe o reCAPTCHA.`;

export function PasswordForgot(scope) {
  'use strict';

  if (!scope) {
    throw new Error('Missing input argument');
  }

  const content = qs(SELECTOR.CLASS_LOGIN_CONTENT, scope);
  content.innerHTML = Template;

  setTimeout(()=> {
    content.removeAttribute('style');
  },300)

  const $btnSendEmail = qs('#btn-send-email');
  const $btnCancelForgot = qs('#btn-cancel-forgot');
  const $input = qs('#input-email');
  const $formGroup = $input.closest('.form-group');
  const recaptcha = reCAPTCHA();

  const start = () => {
    Util.removeClassScope({ scope:scope, className:'login'});
    Util.addClassScope({ scope:scope, className:'page-auth'});

    $btnSendEmail.disabled = true;

    bindEvents();
  };

  const bindEvents = () => {
    $on($input, 'keyup', validateFieldEmail, false);
    $on($btnCancelForgot, 'click', backToLoginPage);
    $on($btnSendEmail, 'click', ({currentTarget}) => sendEmail(currentTarget));

    recaptcha.captchaResolved().then(res => {
      res.on('hugEvent:RecaptchaExpired',() => {
        $btnSendEmail.disabled = !validateFields().formValid ? true : false;
      })
      res.on('hugEvent:RecaptchaResolved',() => {
        validateFieldsAndShowMsg();
      })
    });
  };

  const sendEmail = element => {
    if(validateFields().formValid) {
      enableLoading(element);
      axiosInstance.post('/api/forgotPassword.action',{
        email: $input.value,
        recaptchaToken: recaptcha.getToken()
      }).then((res) => {
        disableLoading();
        if(res.status === 200) {
          $input.value = '';
          $btnSendEmail.disabled = true;
          _messageError('');

          createInstanceModalOld({content: modalSuccess, id: MODAL_MAIN});

          _closeModalAndBackToLogin(MODAL_MAIN);

          recaptcha.reset();
        }
      }).catch((error) => {
        disableLoading();
        createInstanceModalOld({content: modalContent({
          title: 'Atenção!',
          message: error.response.data.message,
          icon: 'warning'
        }), id: MODAL_MAIN});
      });
    } else {
      validateFieldsAndShowMsg();
    }
  };

  const backToLoginPage = () => {
    window.location.href = '/';
  }

  const modalContent = (options) => {
    return [{ title: options.title, description: options.message, icon: options.icon }]
    .reduce((a, item) => a + `
    <div class="hugmodal-dialog" role="document">
      <div class="hugmodal-content">
        <div class="hugmodal-header without-header">
          <div></div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="hugmodal-body">
          <span class="icon-${item.icon}"></span>
          <strong class="title mb-3">${item.title}</strong>
          <p class="mb-4">
            ${item.description}
          </p>
        </div>
      </div>
    </div>
    `, '');
  };

  const validateFieldEmail = () => {
    _removeInputError();

    let fields = validateFields();

    if(!fields.email) {
      _messageError(MSG_INVALID_EMAIL);
    }

    $btnSendEmail.disabled = !fields.formValid ? true : false;
  };

  const validateFieldsAndShowMsg = () => {
    _removeInputError();

    let msg = '';

    if(!validateFields().email) {
      msg += `${MSG_INVALID_EMAIL}<br/>`
    }

    if(!validateFields().recaptcha) {
      msg += `${MSG_INVALID_RECAPTCHA}<br/>`
    }

    _messageError(msg);

    $btnSendEmail.disabled = !validateFields().formValid ? true : false;
  };

  const checkFieldEmail = () => {
    return $input.value && EMAIL_REGEXP.test($input.value) ? true : false;
  };

  const validateFields = () => {
    let result = { email:false, recaptcha:false, formValid:false };
    let valid = [];
    let checkEmail = checkFieldEmail();
    let checkToken = !recaptcha.getToken() ? false : true;

    result.email = checkEmail;
    result.recaptcha = checkToken;

    // validate the form
    valid.push(checkEmail)
    valid.push(checkToken)

    result.formValid = valid.indexOf(false) !== -1 ? false : true;

    return result;
  };

  const _messageError = (message) => {
    _removeInputError();

    if(message) _addInputError();

    qs(`.msg-password-confirm`, $formGroup).innerHTML = message;
  };

  const _closeModalAndBackToLogin = id => {
    $(`#${id}`).on('hidden.bs.modal', function (e) {
      window.location.href = '/';
    });
  };

  const _addInputError = () => $formGroup.classList.add('has-error');

  const _removeInputError = () => $formGroup.classList.remove('has-error');

  return { start };
}

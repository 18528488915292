import * as Helpers from '@services/globalHelpers';
import * as Factory from '@services/globalFactories';

import sessionExpired from '../modals/session-expired.html';
import invalidToken from '../modals/invalid-token.html';

export const modalType = type => {
  const modais = { sessionExpired, invalidToken };

  const instanceModal = Factory.createInstanceModal({
    content: modais[type],
    id:`modal${type}`,
    config: {
      keyboard: false,
      backdrop: false
    }
  });

  _closeModalAndBackToLogin(instanceModal.modal._element);

  return instanceModal;
}

export const resetNotifications = scope => {
  const $notification = (scope || Helpers).qs('#notification');
  const $alert = (scope || Helpers).qs('.alert', $notification);
  const $input = (scope || Helpers).qs('#code');
  const $formGroup = $input.closest('.form-group');

  $alert.innerHTML = '';
  $notification.classList.add('hide');
  $formGroup.classList.remove('has-error');
}

export const showNotification = ({message, type}) => {
  const $notification = Helpers.qs('#notification');
  const $alert = Helpers.qs('.alert', $notification);
  const $input = Helpers.qs('#code');
  const $formGroup = $input.closest('.form-group');

  $notification.classList.remove('hide');
  $alert.classList.remove(`alert-danger`,`alert-warning`);

  $alert.classList.add(`alert-${type}`);
  $alert.innerHTML = message;

  _removeInputError($formGroup);
  _addInputError({type:type, formGroup:$formGroup});
}

const _closeModalAndBackToLogin = element => {
  element.addEventListener('hidden.bs.modal', function (event) {
    window.location.href = '/';
  })
};

const _addInputError = ({formGroup, type}) => {
  if(type === 'danger') {
    formGroup.classList.add('has-error');
  }
}

const _removeInputError = formGroup => formGroup.classList.remove('has-error');
